<template>
  <v-card class="mx-auto" max-width="400" id="infobox">
    <div v-if="!view_all_info">
      <v-card-text>
        <div
          style="float:right; font-weight: bold"
        >{{ properties.FOREST_FILE_ID }} {{properties.HARVEST_AUTH_FOREST_FILE_ID }} {{ properties.CUTTING_PERMIT_ID }} {{properties.HARVEST_AUTH_CUTTING_PERMIT_ID}} {{ properties.CUT_BLOCK_ID }}</div>
        <div>{{ properties.CLIENT_NAME }}</div>
        <p class="display-1 text--primary">
          {{ properties.OPENING_LOCATION_NAME}}
          <span
            v-if="!properties.OPENING_LOCATION_NAME"
          >{{ properties.CUT_BLOCK_ID }}</span>
        </p>

        <div class="harvest-date">
          <p
            v-if="properties.PLANNED_HARVEST_DATE"
          >Approximate Logging Date: {{ properties.PLANNED_HARVEST_DATE }}</p>
          <p v-else>Unknown Logging Date</p>
        </div>
      </v-card-text>

      <v-card-actions v-if="show_all_info_button">
        <v-btn text color="teal accent-4" @click="view_all_info = true">All Info</v-btn>
      </v-card-actions>
    </div>

    <v-expand-transition>
      <v-card
        v-if="view_all_info"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%;"
      >
        <v-card-actions class="pt-0" style="float: right; margin-top: 10px">
          <v-btn text color="teal accent-4" @click="view_all_info = false">Close</v-btn>
        </v-card-actions>
        <v-card-text class="pb-0" style="font-size: x-small">
          <table>
            <tr v-for="item, key in properties_list" :key="key">
              <td>{{ item.key }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>


<script>
// Resources for interpreting properties:
// https://catalogue.data.gov.bc.ca/dataset/results-openings-attribute-only
// https://www.for.gov.bc.ca/hfp/publications/00026/fs708-14-appendix_d.htm
import trees from "@/tree_types.json";

export default {
  props: {
    properties: {
      type: Object,
    },
  },
  data() {
    return {
      view_all_info: false,
      trees,
      show_all_info_button: window.location.href.includes("allinfo"),
      age_classes: {
        1: "",
        2: "",
        3: "60 Years Old",
        4: "80 Years Old",
        5: "100 Years Old",
        6: "120 Years Old",
        7: "140 Years Old",
        8: "200 Years Old",
        9: "Ancient Old-Growth",
      },
    };
  },
  computed: {
    properties_list() {
      let result = [];
      for (const [key, value] of Object.entries(this.properties)) {
        result.push({
          key,
          value,
        });
      }
      return result;
    },
  },
};
</script>
