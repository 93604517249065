var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",staticStyle:{"overflow":"hidden"},attrs:{"width":_vm.$vuetify.breakpoint.lgAndUp ? 450 : 300,"height":_vm.$vuetify.breakpoint.lgAndUp ? 170 : 150,"id":"vegbox"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdAndDown ? 'font-size: smaller' : '')},[(!_vm.view_all_info)?_c('div',{staticStyle:{"padding":"20px"}},[(!_vm.properties.empty)?_c('div',[(!_vm.proj_age || _vm.proj_age > 20)?_c('div',{staticStyle:{"font-weight":"450"}},[(_vm.species_1 && _vm.species_2 && _vm.species_3)?_c('p',[_vm._v(" "+_vm._s(_vm.species_1)+", "+_vm._s(_vm.species_2)+", and "+_vm._s(_vm.species_3)+" ")]):(_vm.species_1 && _vm.species_2)?_c('p',[_vm._v(" "+_vm._s(_vm.species_1)+" and "+_vm._s(_vm.species_2)+" ")]):(_vm.species_1)?_c('p',[_vm._v(_vm._s(_vm.species_1))]):_vm._e()]):(_vm.havest_date)?_c('div',{staticStyle:{"font-weight":"450"}},[_vm._v(" Logged in "+_vm._s(_vm.havest_date)+" ")]):_vm._e(),(
          _vm.proj_age > 300 &&
          _vm.properties.WHOLE_STEM_BIOMASS_PER_HA > 350 &&
          _vm.proj_height > 40
        )?_c('div',[_vm._v(" Giant Ancient Forest ")]):(_vm.proj_age > 300)?_c('div',[_vm._v("Ancient Forest")]):(_vm.proj_age >= 250)?_c('div',[_vm._v(" Old Forest (old-growth or second-growth) ")]):(_vm.proj_age > 20)?_c('div',[_vm._v(_vm._s(_vm.proj_age)+" years old")]):_vm._e()]):_vm._e(),(_vm.endangered_names && _vm.endangered_names.length)?_c('p',[_vm._l((_vm.endangered_names),function(species,index){return _c('span',{key:index},[(_vm.endangered_names.length > 2 && index != 0)?_c('span',[_vm._v(", ")]):_vm._e(),(
            _vm.endangered_names.length != 1 &&
            index == _vm.endangered_names.length - 1
          )?_c('span',[_vm._v(" and ")]):_vm._e(),_c('span',[_vm._v(_vm._s(species))])])}),_vm._v(" habitat ")],2):_vm._e(),(_vm.show_all_info_button)?_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"teal accent-4"},on:{"click":function($event){_vm.view_all_info = true}}},[_vm._v("All Info")])],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticStyle:{"position":"absolute","bottom":"25px","right":"25px"},attrs:{"small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")]):_vm._e()],1):_vm._e()]),_c('v-expand-transition',[(_vm.view_all_info)?_c('v-card',{staticClass:"transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%"}},[_c('v-card-actions',{staticClass:"pt-0",staticStyle:{"float":"right","margin-top":"10px"}},[_c('v-btn',{attrs:{"text":"","color":"teal accent-4"},on:{"click":function($event){_vm.view_all_info = false}}},[_vm._v("Close")])],1),_c('v-card-text',{staticClass:"pb-0",staticStyle:{"font-size":"x-small"}},[_c('table',_vm._l((_vm.properties_list),function(item,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(item.key))]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }