<template>
  <v-card class="mx-auto" :width="$vuetify.breakpoint.lgAndUp ? 450 : 300" :height="$vuetify.breakpoint.lgAndUp ? 170 : 150" id="vegbox" style="overflow: hidden">
    <div :style="$vuetify.breakpoint.mdAndDown ? 'font-size: smaller' : ''">
    <div v-if="!view_all_info" style="padding: 20px">
      <div v-if="!properties.empty">
        <!-- Forest type -->
        <div v-if="!proj_age || proj_age > 20" style="font-weight: 450">
          <p v-if="species_1 && species_2 && species_3">
            {{ species_1 }}, {{ species_2 }}, and {{ species_3 }}
          </p>
          <p v-else-if="species_1 && species_2">
            {{ species_1 }} and {{ species_2 }}
          </p>
          <p v-else-if="species_1">{{ species_1 }}</p>
        </div>

        <div style="font-weight: 450" v-else-if="havest_date">
          Logged in {{ havest_date }}
        </div>
        <div
          v-if="
            proj_age > 300 &&
            properties.WHOLE_STEM_BIOMASS_PER_HA > 350 &&
            proj_height > 40
          "
        >
          Giant Ancient Forest
        </div>
        <div v-else-if="proj_age > 300">Ancient Forest</div>
        <div v-else-if="proj_age >= 250">
          Old Forest (old-growth or second-growth)
        </div>
        <div v-else-if="proj_age > 20">{{ proj_age }} years old</div>
      </div>

      <!-- Endangered species info -->
      <p v-if="endangered_names && endangered_names.length">
        <span v-for="(species, index) in endangered_names" v-bind:key="index">
          <span v-if="endangered_names.length > 2 && index != 0">,&nbsp;</span>
          <span
            v-if="
              endangered_names.length != 1 &&
              index == endangered_names.length - 1
            "
            >&nbsp;and&nbsp;</span
          >
          <span>{{ species }}</span>
        </span>
        habitat
      </p>

      <v-card-actions v-if="show_all_info_button">
        <v-btn text color="teal accent-4" @click="view_all_info = true"
          >All Info</v-btn
        >
      </v-card-actions>

      <v-btn v-if="$vuetify.breakpoint.smAndDown" small @click="$emit('close')" style="position: absolute; bottom: 25px; right: 25px">Close</v-btn>
    </div>
    </div>

    <v-expand-transition>
      <v-card
        v-if="view_all_info"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
      >
        <v-card-actions class="pt-0" style="float: right; margin-top: 10px">
          <v-btn text color="teal accent-4" @click="view_all_info = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text class="pb-0" style="font-size: x-small">
          <table>
            <tr v-for="(item, key) in properties_list" :key="key">
              <td>{{ item.key }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
// Resources for interpreting properties:
// https://catalogue.data.gov.bc.ca/dataset/results-openings-attribute-only
// https://www.for.gov.bc.ca/hfp/publications/00026/fs708-14-appendix_d.htm
import trees from "@/tree_types.json";

export default {
  props: {
    properties: {
      type: Object,
    },
    endangered: {
      type: Array,
    },
  },
  data() {
    return {
      view_all_info: false,
      trees,
      show_all_info_button: window.location.href.includes("allinfo"),
      age_classes: {
        1: "",
        2: "",
        3: "60",
        4: "80",
        5: "100",
        6: "120",
        7: "140",
        8: "200",
        9: "350",
      },
    };
  },
  computed: {
    species_1() {
      if (this.properties.SPECIES_CD_1) {
        return this.trees[this.properties.SPECIES_CD_1];
      }
      return "";
    },
    species_2() {
      if (this.properties.SPECIES_CD_2) {
        return this.trees[this.properties.SPECIES_CD_2];
      }
      return "";
    },
    species_3() {
      if (this.properties.SPECIES_CD_3) {
        return this.trees[this.properties.SPECIES_CD_3];
      }
      return "";
    },
    proj_height() {
      if (this.properties.PROJ_HEIGHT_1 > this.properties.PROJ_HEIGHT_2) {
        return this.properties.PROJ_HEIGHT_1;
      } else {
        return this.properties.PROJ_HEIGHT_2;
      }
    },
    proj_age() {
      if (this.properties.PROJ_AGE_1) {
        if (
          !this.properties.PROJ_AGE_2 ||
          this.properties.PROJ_AGE_1 > this.properties.PROJ_AGE_2
        ) {
          return this.properties.PROJ_AGE_1;
        } else {
          return this.properties.PROJ_AGE_2;
        }
      } else if (this.properties.PROJ_AGE_CLASS_CD_1) {
        if (
          !this.properties.PROJ_AGE_CLASS_CD_2 ||
          this.properties.PROJ_AGE_CLASS_CD_1 >
            this.properties.PROJ_AGE_CLASS_CD_2
        ) {
          return this.age_classes[this.properties.PROJ_AGE_CLASS_CD_1];
        } else {
          return this.age_classes[this.properties.PROJ_AGE_CLASS_CD_2];
        }
      } else return 0;
    },
    havest_date() {
      if (this.properties.HARVEST_DATE) {
        return this.properties.HARVEST_DATE.substr(0, 4);
      }
      return "";
    },
    properties_list() {
      let result = [];
      for (const [key, value] of Object.entries(this.properties)) {
        result.push({
          key,
          value,
        });
      }
      return result;
    },
    endangered_names() {
      let names = [];
      this.endangered.forEach((species) => {
        if (species.EL_TYPE == "Ecological Community") {
          return;
        }

        var full_name;
        if (species.taxon) {
          let taxon = JSON.parse(species.taxon);
          full_name = taxon.preferred_common_name
            ? taxon.preferred_common_name
            : taxon.name;
        } else {
          full_name = species.CommName_E
            ? species.CommName_E
            : species.ENG_NAME;
        }

        if (full_name) {
          let parts = full_name.split(",");
          let name = parts[0];
          if (parts[1]) {
            name += " (" + parts[1].trim() + ")";
          }

          if (!names.includes(name)) {
            names.push(name);
          }
        }
      });
      return names;
    },
  },
};
</script>
