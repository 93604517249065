<template>
  <v-app>
    <v-app-bar app color="primary" dark :dense="$vuetify.breakpoint.mdAndDown">
      <div class="h2" :style="$vuetify.breakpoint.mdAndDown ? '' : 'font-size: x-large;'">BC Forest Map</div>

      <v-spacer></v-spacer>

      <v-btn target="_blank" text @click="data_sources = true">
        <span class="mr-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size: x-small;' : ''">Data Sources</span>
      </v-btn>
      <v-btn target="_blank" text @click="contact_us = true">
        <span class="mr-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size: x-small;' : ''">Contact Us</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <ForestMap />

      <v-dialog v-model="contact_us" :width="$vuetify.breakpoint.mdAndDown ? 400 : 500">
        <v-card>
          <v-card-title class="headline grey lighten-2">Contact Us</v-card-title>
          <br />
          <v-card-text :style="$vuetify.breakpoint.mdAndDown ? 'font-size:1.2rem' : 'font-size:1.5rem'">patrick.d.hayes@gmail.com</v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="data_sources" width="1000">
        <v-card>
          <v-card-title class="headline grey lighten-2">Data Sources</v-card-title>

          <br />
          <v-card-text>
            <h3>Planned Logging Data</h3>
            <p>
              <ol>
                <li><a
                href="https://catalogue.data.gov.bc.ca/dataset/forest-tenure-cutblock-polygons-fta-4-0"
              >Forest Tenure Cutblock Polygons</a>, filtered for planned harvest dates between a year in the past to 5 years in the future.
              </li>
              <li><a href="https://catalogue.data.gov.bc.ca/dataset/results-openings-svw">RESULTS - Openings svw</a>, filtered in the following way: <code>APPROVE_DATE AFTER {one_year_ago} AND DISTURBANCE_END_DATE IS NULL AND FEATURE_AREA > 0 AND (DENUDATION_COUNT IS NULL OR DENUDATION_COUNT = 0) AND (PLANTING_COUNT IS NULL OR PLANTING_COUNT = 0))</code></li>
              </ol>
            </p>

            <h3>Protected Areas</h3>
            <p>
              <ol>
                <li><a
                href="https://openstreetmap.org/"
              >Open Street Maps</a>, filtered using <code>boundary=protected_area</code>.
              </li>
              <li><a href="https://catalogue.data.gov.bc.ca/dataset/old-growth-management-areas-legal-current">Old Growth Management Areas - Legal - Current</a>.</li>
              <li><a href="https://catalogue.data.gov.bc.ca/dataset/wildlife-habitat-areas-approved">Wildlife Habitat Areas - Approved</a>, filtered using <code>"TIMBER_HARVEST_CODE" = 'NO HARVEST ZONE'</code>.</li>
              </ol>
            </p>

            <h3>Endangered Species</h3>
            <p>
              <ol>
                <li><a
                href="https://catalogue.data.gov.bc.ca/dataset/species-and-ecosystems-at-risk-publicly-available-occurrences-cdc"
              >Species and Ecosystems at Risk - Publicly Available Occurrences - CDC</a>, filtered to exclude occurences obtained via modelling (Right now this excludes modelled marbled murrelet habitat).
              </li>

              <li>
              Canada Federal Government SARA data for BC
              </li>

              <li><a
                href="https://www.inaturalist.org"
              >iNaturalist</a>, filtered in the following way: <code>quality_grade=research acc_below=50 threatened=true taxon_geoprivacy=open</code>
              </li>
              
              </ol>
            </p>

            <h3>Forest Data</h3>
            <p>
              Forest Data is obtained from the <a href="https://catalogue.data.gov.bc.ca/dataset/vri-2020-forest-vegetation-composite-rank-1-layer-r1-/">Vegetation Resource Inventory</a>. The VRI data tends to be good for recent planted stands, and is fairly poor for older old-growth stands. For this reason, we don't report the exact age for old-growth forests and use the following hueristics:
              <ul>
                <li> <code>proj_age > 300</code> "Ancient Forest" (before first contact)</li>
                <li> <code>proj_age > 250</code> "Old Forest" (after first contact)</li>
                <li> <code>proj_age > 20</code> Report the age</li>
              </ul>
            </p>

            <h3>Species Observations</h3>
            <p>
              <ol>
              <li><a
                href="https://bigtrees.forestry.ubc.ca/bc-bigtree-registry/"
              >BC BigTree Registry</a>, used to show big tree locations.
              </li>

              <li><a
                href="https://www.inaturalist.org"
              >iNaturalist</a>, used to show observations.
              </li>
              </ol>
            </p>

            <h3>Satellite and aerial imagery</h3>
              <p>
              <ol>
                <li>NASA MODIS satellite imagery</li>
                <li>Maxar Vivid and NASA/USGS Landsat 5 & 7 satellite imagery</li>
                <li>Open Aerial imagery</li>
              </ol>
            </p>

            <h3>Terrain Elevation Data</h3>
            <p>
              <ul><li><a href="https://www.mapbox.com/blog/realistic-3d-experience">Mapbox 3D Terrain</a></li></ul>
            </p>

            <h3>Roads, streams, trails etc.</h3>
            <p>
              <ul><li><a href="https://openstreetmap.org">Open Street Maps</a></li></ul>
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import ForestMap from "./components/ForestMap";

export default {
  name: "App",

  components: {
    ForestMap,
  },

  data: () => ({
    contact_us: false,
    data_sources: false,
  }),
};
</script>
