<template>
  <div style="width: 100%; height: 100%">
    <div id="map"></div>
    <InfoBox
      v-if="show_infobox"
      :properties="infobox_properties"
      :style="infobox_style"
    />
    <VegInfo
      v-if="show_vegbox"
      :properties="vegbox_properties"
      :endangered="endangered_info"
      :style="vegbox_style"
      @close="show_vegbox = false"
    />

    <!-- Show if breakpoint is larger than small-->
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      style="position: absolute; right: 280px; top: 11px"
      @click="toggle_3d"
    >
      <span v-if="pitch_angle > 0">Disable 3D</span>
      <span v-else>View in 3D</span>
    </v-btn>

    <v-sheet
      v-if="pitch_angle > 0 && !qe_pressed && $vuetify.breakpoint.mdAndUp"
      style="
        position: absolute;
        right: 235px;
        top: 61px;
        background-color: rgba(255, 255, 255, 0.8);
      "
      class="pa-2"
      rounded
    >
      Use Q and E to rotate
    </v-sheet>

    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      style="position: absolute; left: 10px; top: 10px"
      @click="toggle_legend"
    >
      <v-icon> mdi-menu </v-icon>
    </v-btn>

    <Geocoder
      style="position: absolute; right: 30px; top: 10px"
      @select="geocoded"
    />

    <!-- Legend -->
    <v-card
      v-if="show_legend"
      class="mx-auto"
      :width="$vuetify.breakpoint.lgAndUp ? 450 : 300"
      id="legend"
    >
      <v-card-text>
        <div class="text--primary">
          <div>
            <span style="color: red" class="legend-color">■</span> Planned
            Logging
          </div>
          <div>
            <span style="color: #6fa451" class="legend-color">■</span> Protected
            <br />
            <span style="padding-left: 31px; font-size: smaller"
              >Solid: Parks</span
            >
            <br />
            <span style="padding-left: 31px; font-size: smaller">
              <span v-if="$vuetify.breakpoint.lgAndUp"
                >Dashed: Old Growth (OMGA) and Wildlife (WHA) Areas</span
              >
              <span v-else>Dashed: OMGA and WHA </span>
              <span v-if="current_zoom <= 11">(zoom-in to see)</span>
            </span>

            <!-- Optional Layers -->
            <v-checkbox
              v-model="highlight_species"
              color="yellow"
              hide-details
              :disabled="!layers_loaded"
            >
              <template v-slot:label>
                <span
                  :style="
                    $vuetify.breakpoint.mdAndDown ? 'font-size: smaller' : ''
                  "
                  >Highlight Endangered Species</span
                >
                <span
                  v-if="!highlight_species && $vuetify.breakpoint.lgAndUp"
                  style="
                    margin-left: 2px;
                    font-size: smaller;
                    position: relative;
                    top: 1px;
                  "
                  >- disabled (click to enable)</span
                >
              </template>
            </v-checkbox>

            <v-checkbox
              v-model="highlight_oldgrowth"
              color="#3d59c3"
              hide-details
              :disabled="!layers_loaded"
            >
              <template v-slot:label>
                <span
                  :style="
                    $vuetify.breakpoint.mdAndDown ? 'font-size: smaller' : ''
                  "
                  >Highlight Old-growth Forest</span
                >
                <span
                  v-if="!highlight_oldgrowth && $vuetify.breakpoint.lgAndUp"
                  style="
                    margin-left: 2px;
                    font-size: smaller;
                    position: relative;
                    top: 1px;
                  "
                  >- disabled (click to enable)</span
                >
                <span
                  v-else-if="current_zoom <= 8.01"
                  style="
                    margin-left: 5px;
                    font-size: smaller;
                    position: relative;
                    top: 1px;
                  "
                  >(zoom-in to see)</span
                >
              </template>
            </v-checkbox>

            <div
              class="d-flex flex-row justify-space-around"
              style="margin-bottom: -30px; height: 50px"
            >
              <!-- <p v-if="highlight_oldgrowth && current_zoom <= 8.01">zoom-in to see ancient forest</p> -->
              <v-slider
                v-if="highlight_oldgrowth"
                :disabled="current_zoom <= 8.01"
                label="Age"
                max="400"
                min="250"
                v-model="vri_min_age"
              ></v-slider>
              <v-slider
                v-if="highlight_oldgrowth"
                :disabled="current_zoom <= 8.01"
                label="Height"
                max="45"
                min="30"
                v-model="vri_min_height"
              ></v-slider>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- Small Screen Observation Dialog -->
    <v-dialog v-model="observation_dialog" fullscreen hide-overlay>
      <v-card class="small_screen_dialog">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="observation_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ observation_title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div
            style="font-size: larger; padding-top: 20px"
            v-html="observation_html"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import InfoBox from "./InfoBox";
import VegInfo from "./VegInfo";
import Geocoder from "./Geocoder";
import big_trees_geojson from "../big_trees.json";
import axios from "axios";
import jQuery from "jquery";

const metersToPixelsAtMaxZoom = (meters, latitude) => {
  let estimate = meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

  if (estimate < 5) {
    estimate = 5;
  }
  return estimate;
};

export default {
  name: "ForestMap",
  components: { InfoBox, VegInfo, Geocoder },

  data() {
    var hash_zoom, hash_lat, hash_lng;

    // Parse location from window hash
    if (window.location.hash) {
      let parts = window.location.hash.substr(1).split("/");
      hash_zoom = Number(parts[0]);
      hash_lat = Number(parts[1]);
      hash_lng = Number(parts[2]);
    }

    return {
      map: null,
      infobox_properties: {},
      vegbox_properties: {},
      endangered_info: [],
      show_infobox: false,
      show_vegbox: false,
      show_legend: this.$vuetify.breakpoint.mdAndUp,
      infobox_type: "opening",
      autohide_boxes: !window.location.href.includes("allinfo"),
      highlight_oldgrowth: false,
      highlight_species: false,
      current_zoom: hash_zoom ? hash_zoom : 5.5,
      start_lat: hash_lat ? hash_lat : 51.5,
      start_lng: hash_lng ? hash_lng : -123,
      vri_min_age: 300,
      vri_min_height: 40,
      layers_loaded: false,
      threed_message: "View in 3D",
      twod_message: "View in 2D",
      three_d_enabled: null,
      pitch_angle: 0,
      qe_pressed: false,
      observation_dialog: false,
      observation_title: "",
      observation_html: "",
      inat_data: [],
      fsr_alerts: null,
    };
  },

  computed: {
    vegbox_style() {
      return {
        width: this.$vuetify.breakpoint.lgAndUp ? "450px" : "300px",
        position: "absolute",
        left: "20px",
        bottom: this.show_legend ? "250px" : "20px",
        height: "160px",
        overflow: "hidden",
      };
    },
    infobox_style() {
      return {
        width: this.$vuetify.breakpoint.lgAndUp ? "500px" : "400px",
        position: "absolute",
        left: "20px",
        top: this.$vuetify.breakpoint.smAndDown ? "60px" : "20px",
      };
    },
  },

  watch: {
    highlight_oldgrowth() {
      this.adjust_oldgrowth_highlight();
    },
    vri_min_age() {
      this.adjust_oldgrowth_highlight();
    },
    vri_min_height() {
      this.adjust_oldgrowth_highlight();
    },

    highlight_species(highlight_species) {
      if (this.map.getLayer("bc_species_habitat-fill")) {
        if (highlight_species) {
          this.map.setPaintProperty(
            "bc_species_habitat-fill",
            "fill-opacity",
            0.3
          );
          this.map.setPaintProperty(
            "inaturalist_species_habitat-fill",
            "circle-opacity",
            0.3
          );
        } else {
          this.map.setPaintProperty(
            "bc_species_habitat-fill",
            "fill-opacity",
            0
          );
          this.map.setPaintProperty(
            "inaturalist_species_habitat-fill",
            "circle-opacity",
            0
          );
        }
      }
    },
  },

  mounted() {
    let self = this;

    mapboxgl.accessToken =
      "pk.eyJ1IjoicGhheWVzIiwiYSI6InNHMlkzQUkifQ.C9wmsbr-8tAtViMNb1wEcA";
    const map = new mapboxgl.Map({
      container: "map", // container ID
      style: "mapbox://styles/phayes/ckneon6g61irt17pe7bj34cwe", // style URL
      center: [this.start_lng, this.start_lat], // starting position [lng, lat]
      zoom: this.current_zoom, // starting zoom
      pitch: 0,
      bearing: 0,
      maxBounds: [
        [-145, 45],
        [-110, 63],
      ],
    });
    this.map = map;

    // Add geolocate control to the map.
    if ("ontouchend" in window.document) {
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        })
      );
    } else {
      map.addControl(new mapboxgl.NavigationControl());
    }

    // disable map rotation
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();

    map.on("load", function () {
      map.getCanvas().style.cursor = "pointer";
    });

    // Set QE controls for rotation
    let rotating_left = false;
    let rotating_right = false;
    map.on("load", () => {
      window.addEventListener("keydown", (e) => {
        if (map.getPitch() != 0 && e.key === "q") {
          rotating_left = true;
          rotating_right = false;
        } else if (map.getPitch() != 0 && e.key === "e") {
          rotating_right = true;
          rotating_left = false;
        }
      });
      window.addEventListener("keyup", (e) => {
        if (e.key === "q" || e.key === "e") {
          rotating_right = false;
          rotating_left = false;
          this.qe_pressed = true;
        }
      });

      setInterval(() => {
        if (rotating_left) {
          let bearing = map.getBearing();
          map.setBearing(bearing - 1);
        } else if (rotating_right) {
          let bearing = map.getBearing();
          map.setBearing(bearing + 1);
        }
      }, 5);
    });

    // Change pitch to 45 degrees between zoom 9 and 11
    map.on("zoom", () => {
      if (this.$vuetify.breakpoint.smAndDown) {
        return;
      }
      const zoom = map.getZoom();
      const current_pitch = map.getPitch();
      if (this.three_d_enabled === null && zoom >= 9 && zoom <= 11) {
        const ratio = (zoom - 9) / 2;
        const target_pitch = 45 * ratio;
        if (Math.abs(current_pitch - target_pitch) > 1) {
          map.setPitch(target_pitch);
        }
        if (!map.getTerrain()) {
          this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1.3 });
        }
      }
    });

    map.on("zoomend", () => {
      if (this.$vuetify.breakpoint.smAndDown) {
        return;
      }
      const zoom = map.getZoom();
      const current_pitch = map.getPitch();
      if (this.three_d_enabled === null && zoom > 11) {
        if (current_pitch != 45) {
          map.setPitch(45);
        }
        if (!map.getTerrain()) {
          this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1.3 });
        }
      } else if (this.three_d_enabled === null && zoom < 9) {
        if (current_pitch != 0) {
          map.setPitch(0);
        }
        if (map.getBearing()) {
          map.setBearing(0);
        }
        if (map.getTerrain()) {
          this.map.setTerrain(null);
        }
      }
    });

    map.on("pitchend", () => {
      this.pitch_angle = map.getPitch();
    });

    // Add layers
    map.on("load", function () {
      // Set cursor
      map.getCanvas().style.cursor = "grab";

      // Set Terrain
      map.addSource("mapbox-dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14,
      });

      // Cuts from openings - line
      map.addLayer({
        id: "all-cuts",
        type: "line",
        source: {
          type: "vector",
          url: "mapbox://phayes.7k5f6zdt",
        },
        "source-layer": "all_planned_cuts",
        paint: {
          "line-color": "#ff0000",
          "line-width": 5,
        },
      });

      // Cuts From Openings - fill
      map.addLayer({
        id: "all-cuts-fill",
        type: "fill",
        source: {
          type: "vector",
          url: "mapbox://phayes.7k5f6zdt",
        },
        "source-layer": "all_planned_cuts",
        paint: {
          "fill-color": "rgba(0, 0, 0, 0.0)",
        },
      });

      // Next 5 years cutblocks - line
      map.addLayer({
        id: "ften_cut_block_n5y",
        type: "line",
        source: {
          type: "vector",
          url: "mapbox://phayes.ften_cut_block_n5y",
        },
        "source-layer": "ften_cut_block_n5y",
        paint: {
          "line-color": "#ff0000",
          "line-width": 5,
        },
      });

      // Next 5 years cut blocks Fill (for data)
      map.addLayer({
        id: "ften_cut_block_n5y-fill",
        type: "fill",
        source: {
          type: "vector",
          url: "mapbox://phayes.ften_cut_block_n5y",
        },
        "source-layer": "ften_cut_block_n5y",
        paint: {
          "fill-color": "rgba(0, 0, 0, 0.0)",
        },
      });

      // BC Species at Risk
      map.addLayer({
        id: "bc_species_habitat-fill",
        type: "fill",
        source: {
          type: "vector",
          url: "mapbox://phayes.species_at_risk",
        },
        "source-layer": "species_at_risk",
        paint: {
          "fill-color": "yellow",
          "fill-opacity": 0,
        },
        filter: [
          "all",
          ["!=", "BC_LIST", "Yellow"],
          ["!=", "EL_TYPE", "Ecological Community"],
          ["!=", "ENG_NAME", "Painted Turtle - Pacific Coast Population"], // Remove duplicate due to SARA / BC reporting same
        ],
      });

      // iNatualist Species at risk
      map.addLayer({
        id: "inaturalist_species_habitat-fill",
        type: "circle",
        source: {
          type: "vector",
          url: "mapbox://phayes.inaturalist_species_at_risk",
        },
        "source-layer": "inaturalist",
        paint: {
          "circle-color": "yellow",
          "circle-opacity": 0,
          "circle-radius": {
            stops: [
              [0, 0],
              [20, metersToPixelsAtMaxZoom(100, 50)],
            ],
            base: 2,
          },
        },
        filter: ["all", ["<", "public_positional_accuracy", 100]],
      });

      // VRI map
      map.addLayer({
        id: "vri",
        type: "fill",
        source: {
          type: "vector",
          url: "mapbox://phayes.vri",
        },
        "source-layer": "vri",
        paint: {
          "fill-color": "blue",
          "fill-opacity": 0,
        },

        minzoom: 8,
      });

      // Add inaturalist layer
      map.loadImage("/circle_icon.png", function (error, image) {
        if (error) throw error;
        map.addImage("circle-icon", image);
        map.addSource("inaturalist-geojson", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });
        map.addLayer({
          id: "inaturalist",
          type: "symbol",
          source: "inaturalist-geojson",
          layout: {
            "icon-image": "circle-icon",
            "icon-size": self.$vuetify.breakpoint.smAndDown ? 0.08 : 0.05,
            // get the title name from the source's "title" property
            "text-field": ["get", "display_name"],
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-size": 10,
            "text-offset": [0, 1],
            "text-anchor": "top",
            "symbol-sort-key": ["get", "sort_order"],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          },
          paint: {
            "text-color": "#FFFFFF",
            "icon-opacity": {
              stops: [
                [13.5, 0],
                [14, 1],
              ],
            },
            "text-opacity": {
              stops: [
                [13.5, 0],
                [14, 1],
              ],
            },
          },
        });

        map.on("mousemove", "inaturalist", (_event) => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "inaturalist", (_event) => {
          map.getCanvas().style.cursor = "grab";
        });

        // When a click event occurs on a feature in the places layer, open a popup at the
        // location of the feature, with description HTML from its properties.
        map.on("click", "inaturalist", function (e) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          var p = e.features[0].properties;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          let photos = JSON.parse(p.photos);
          let taxon = JSON.parse(p.taxon);

          let photos_html = "<div class='inat_photos'>";
          for (var photo of photos) {
            if (photo.url) {
              let url = photo.url;
              let large_url = photo.url.replace("square", "large");
              photos_html +=
                "<a target='_blank' href='" +
                large_url +
                "'><img src='" +
                url +
                "' /></a>";
            }
          }
          photos_html += "</div>";

          let wikipedia_html = "";
          if (taxon.wikipedia_url) {
            wikipedia_html =
              "<p>Wikipedia: <a target='_blank' href='" +
              taxon.wikipedia_url +
              "'>" +
              taxon.wikipedia_url
                .replace("https://en.", "")
                .replace("http://en.", "") +
              "</a></p>";
          }

          let html =
            "<div style='width: 500px; max-height: 500px; overflow-y:scroll'><h3>" +
            (taxon.preferred_common_name
              ? taxon.preferred_common_name
              : p.display_name) +
            "</h3>" +
            "<p style='font-style: italic;'>" +
            taxon.name +
            "</p>" +
            "<h4>" +
            (taxon.threatened ? "Endangered / Threatened" : "") +
            "</h4>" +
            "<p>" +
            (p.description && p.description != "null" ? p.description : "") +
            "</p>" +
            "<p>" +
            photos_html +
            "</p>" +
            wikipedia_html +
            "<p>Observation source: <a target='_blank' href='" +
            p.uri +
            "'>iNaturalist</a></p>" +
            "</div>";

          if (self.$vuetify.breakpoint.smAndDown) {
            self.observation_title = taxon.preferred_common_name
              ? taxon.preferred_common_name
              : p.display_name;
            self.observation_html = html;
            self.observation_dialog = true;
            return;
          } else {
            new mapboxgl.Popup({ maxWidth: 500 })
              .setLngLat(coordinates)
              .setHTML(html)
              .addTo(map);
          }
        });

        if (self.current_zoom > 14) {
          self.fetch_inaturalist();
        }
      });

      // Add big-tree layer
      map.loadImage("/tree_icon_small.png", function (error, image) {
        if (error) throw error;
        map.addImage("big-tree-marker", image);

        map.addSource("big-trees-geojson", {
          type: "geojson",
          data: big_trees_geojson,
        });
        map.addLayer({
          id: "big-trees",
          type: "symbol",
          source: "big-trees-geojson",
          layout: {
            "icon-image": "big-tree-marker",
            "icon-size": self.$vuetify.breakpoint.smAndDown ? 0.5 : 0.3,
            // get the title name from the source's "title" property
            "text-field": ["get", "tree_nickname"],
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-size": 10,
            "text-offset": [0, 1],
            "text-anchor": "top",
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": "#FFFFFF",
            "icon-opacity": {
              stops: [
                [9, 0],
                [10, 1],
              ],
            },
            "text-opacity": {
              stops: [
                [9, 0],
                [10, 1],
              ],
            },
          },
        });
      });

      map.on("mousemove", "big-trees", (_event) => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "big-trees", (_event) => {
        map.getCanvas().style.cursor = "grab";
      });

      // When a click event occurs on a feature in the places layer, open a popup at the
      // location of the feature, with description HTML from its properties.
      map.on("click", "big-trees", function (e) {
        var coordinates = e.features[0].geometry.coordinates.slice();
        var p = e.features[0].properties;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let photos_html = "";
        if (p.has_photo === "1") {
          var photo_html = "<div>" + p["all photo LINK"] + "</div>";

          jQuery(photo_html)
            .find("a")
            .each(function () {
              let photo_href = jQuery(this).attr("href");
              photos_html +=
                "<a target='_blank' href='" +
                photo_href +
                "'><img src='" +
                photo_href +
                "' height='100' style='margin-right: 10px'/></a>";
            });
        }
        if (photos_html) {
          photos_html = "<p>" + photos_html + "</p>";
        }

        let rank_html = "";
        if (p.rank == 1) {
          rank_html = "Largest " + p.common_name + " in BC. ";
        } else if (p.rank == 2) {
          rank_html = "Second largest " + p.common_name + " in BC. ";
        } else if (p.rank == 3) {
          rank_html = "Third largest " + p.common_name + " in BC. ";
        }

        let html =
          "<div class='big_trees_observation'><h4 class='big_tree_nickname'>" +
          p.tree_nickname +
          "</h4><h4>" +
          p.common_name +
          "</h4>" +
          "<p>" +
          rank_html +
          p.tree_site_notes +
          "</p>" +
          "<p>" +
          p.access_notes +
          "</p>" +
          photos_html +
          "<p>source: <a href='https://bigtrees.forestry.ubc.ca/bc-bigtree-registry/'>BC Big Tree Registry</a>" +
          " (Tree #" +
          p.tree_registry_id +
          ")";
        ("</div>");

        if (self.$vuetify.breakpoint.smAndDown) {
          self.observation_title = p.tree_nickname
            ? p.tree_nickname
            : p.common_name;
          self.observation_html = html;
          self.observation_dialog = true;
          return;
        } else {
          new mapboxgl.Popup({ maxWidth: 500 })
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(map);
        }
      });

      self.layers_loaded = true;

      // Trigger zoom
      map.fire("zoom", {});
      map.fire("zoomend", {});
    });

    map.on("zoomend", function () {
      let zoom = map.getZoom();
      self.current_zoom = zoom;
      if (zoom >= 8 && self.$vuetify.breakpoint.mdAndUp) {
        self.show_vegbox = true;
      } else {
        self.show_vegbox = false;
      }

      // Set the anchor
      var { lat, lng } = map.getCenter();
      window.location.hash = "#" + zoom + "/" + lat + "/" + lng;

      if (zoom > 14) {
        self.fetch_inaturalist();
      }

      if (zoom >= 11) {
        self.maybe_load_fsr_alerts();
      }
    });

    var moveend_inat_timeout_trigger;
    map.on("moveend", function () {
      // Set the anchor
      var { lat, lng } = map.getCenter();
      let zoom = map.getZoom();
      window.location.hash = "#" + zoom + "/" + lat + "/" + lng;

      if (zoom > 14) {
        if (moveend_inat_timeout_trigger) {
          clearTimeout(moveend_inat_timeout_trigger);
        }
        moveend_inat_timeout_trigger = setTimeout(() => {
          self.fetch_inaturalist();
        }, 200);
      }
    });

    // Trigger vegbox
    // See https://www.for.gov.bc.ca/hfd/library/documents/bib106996.pdf
    map.on("mousemove", "vri", function (e) {
      if (e.features.length > 0) {
        self.vegbox_properties = e.features[0].properties;
        if (self.current_zoom >= 8) {
          self.show_vegbox = true;
        }
      } else if (self.autohide_boxes) {
        console.log("here");
        self.vegbox_properties = { empty: true };
        if (self.$vuetify.breakpoint.smAndDown) {
          self.show_vegbox = false;
        }
      }
    });

    map.on("mouseenter", "vri", function (e) {
      self.vegbox_properties = e.features[0].properties;
      if (self.current_zoom >= 8) {
        self.show_vegbox = true;
      }
    });
    map.on("mouseleave", "vri", function (_e) {
      if (self.autohide_boxes) self.vegbox_properties = { empty: true };
      if (self.$vuetify.breakpoint.smAndDown) {
        self.show_vegbox = false;
      }
    });

    // Trigger InfoBox
    map.on("mouseenter", "all-cuts-fill", function (e) {
      self.infobox_properties = e.features[0].properties;
      self.show_infobox = true;
      self.infobox_type = "opening";
    });
    map.on("mouseleave", "all-cuts-fill", function (_e) {
      if (self.autohide_boxes) {
        self.show_infobox = false;
      }
    });

    map.on("mouseenter", "ften_cut_block_n5y-fill", function (e) {
      self.infobox_properties = e.features[0].properties;
      self.show_infobox = true;
      self.infobox_type = "harvest";
    });
    map.on("mouseleave", "ften_cut_block_n5y-fill", function (_e) {
      if (self.autohide_boxes) {
        self.show_infobox = false;
      }
    });

    map.on("mouseenter", "bc_species_habitat-fill", function (e) {
      self.endangered_info = [];
      e.features.forEach((f) => {
        self.endangered_info.push(f.properties);
      });
    });
    map.on("mousemove", "bc_species_habitat-fill", function (e) {
      if (e.features.length > 0) {
        self.endangered_info = [];
        e.features.forEach((f) => {
          self.endangered_info.push(f.properties);
        });
      } else if (self.autohide_boxes) {
        self.endangered_info = [];
      }
    });
    map.on("mouseleave", "bc_species_habitat-fill", function (_e) {
      if (self.autohide_boxes) {
        self.endangered_info = [];
      }
    });

    map.on("mouseenter", "inaturalist_species_habitat-fill", function (e) {
      self.endangered_info = [];
      e.features.forEach((f) => {
        self.endangered_info.push(f.properties);
      });
    });
    map.on("mousemove", "inaturalist_species_habitat-fill", function (e) {
      if (e.features.length > 0) {
        self.endangered_info = [];
        e.features.forEach((f) => {
          self.endangered_info.push(f.properties);
        });
      } else if (self.autohide_boxes) {
        self.endangered_info = [];
      }
    });
    map.on("mouseleave", "inaturalist_species_habitat-fill", function (_e) {
      if (self.autohide_boxes) {
        self.endangered_info = [];
      }
    });
  },

  methods: {
    maybe_load_fsr_alerts() {
      if (this.fsr_alerts === null) {
        axios
          .get(
            "https://services6.arcgis.com/ubm4tcTYICKBpist/arcgis/rest/services/FSR_Safety_Information_View/FeatureServer/0/query?f=geojson&where=1%3D1&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=ALERT_TYPE%2CLOCATION%2CINFORMATION%2CNOTICE%2COBJECTID"
          )
          .then((response) => {
            this.fsr_alerts = response.data;

            this.map.loadImage("/alert.png", (error, image) => {
              if (error) throw error;
              this.map.addImage("alert-icon", image);

              this.map.addSource("fsr_alerts", {
                type: "geojson",
                data: this.fsr_alerts,
              });

              // Add layer with alert icon
              // Only zoom at 11+
              this.map.addLayer({
                id: "fsr_alerts",
                type: "symbol",
                source: "fsr_alerts",
                layout: {
                  "icon-image": "alert-icon",
                  "icon-size": 0.1,
                  "text-field": ["get", "ALERT_TYPE"],
                  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  "text-size": 10,
                  "text-offset": [0, 1],
                  "text-anchor": "top",
                  "icon-allow-overlap": true,
                  "icon-ignore-placement": true,
                },
                paint: {
                  "text-color": "#FFFFFF",
                  "icon-opacity": {
                    stops: [
                      [12, 0],
                      [13, 1],
                    ],
                  },
                  "text-opacity": {
                    stops: [
                      [12, 0],
                      [13, 1],
                    ],
                  },
                },
              });

              this.map.on("mousemove", "fsr_alerts", (_event) => {
                this.map.getCanvas().style.cursor = "pointer";
              });
              this.map.on("mouseleave", "fsr_alerts", (_event) => {
                this.map.getCanvas().style.cursor = "grab";
              });

              // Add click event with popup info
              this.map.on("click", "fsr_alerts", (e) => {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var p = e.features[0].properties;

                let notice = "";

                if (p.NOTICE) {
                  // Check if notice is a URL
                  if (p.NOTICE.trim().startsWith("http")) {
                    notice =
                      "<p><a target='_blank' href='" +
                      p.NOTICE.trim() +
                      "'>More information</a></p>";
                  } else {
                    notice = "<p>" + p.NOTICE + "</p>";
                  }
                }

                let html =
                  "<div style='width: 500px; max-height: 500px; overflow:hidden'><h3>" +
                  p.ALERT_TYPE +
                  "</h3>" +
                  "<p>" +
                  p.LOCATION +
                  "</p>" +
                  "<p>" +
                  p.INFORMATION +
                  "</p>" +
                  notice +
                  "</div>";

                if (this.$vuetify.breakpoint.smAndDown) {
                  this.observation_title = p.ALERT_TYPE;
                  this.observation_html = html;
                  this.observation_dialog = true;
                  return;
                } else {
                  new mapboxgl.Popup({ maxWidth: 500 })
                    .setLngLat(coordinates)
                    .setHTML(html)
                    .addTo(this.map);
                }
              });
            });
          });
      }
    },
    toggle_legend() {
      this.show_legend = !this.show_legend;
    },
    geocoded(val) {
      if (!val) {
        return;
      }
      console.log(val);
      if (val.boundingbox) {
        let bbox = val.boundingbox;
        this.map.fitBounds(
          [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]],
          ],
          { padding: 50, animate: false, maxZoom: 16 }
        );
      } else {
        this.map.flyTo({
          center: [val.lon, val.lat],
          zoom: 15,
          animate: false,
        });
      }
      this.map.getCanvas().focus();
    },
    toggle_3d() {
      if (this.pitch_angle > 0) {
        this.three_d_enabled = false;
        this.map.setPitch(0);
        this.map.setBearing(0);
        this.map.setTerrain(null);
      } else {
        this.three_d_enabled = true;
        this.map.setPitch(45);
        this.map.setBearing(0);
        this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1.3 });
      }
    },
    adjust_oldgrowth_highlight() {
      if (this.map.getLayer("vri")) {
        if (this.highlight_oldgrowth) {
          this.map.setPaintProperty("vri", "fill-opacity", this.vri_opacity());
        } else {
          this.map.setPaintProperty("vri", "fill-opacity", 0);
        }
      }
    },
    vri_opacity() {
      let opapacity_expression = [
        "case",
        [
          "all",
          [">=", ["to-number", ["get", "PROJ_AGE_1"]], this.vri_min_age],
          [">=", ["to-number", ["get", "PROJ_HEIGHT_1"]], this.vri_min_height],
        ],
        0.5,
        [
          "all",
          [">=", ["to-number", ["get", "PROJ_AGE_2"]], this.vri_min_age],
          [">=", ["to-number", ["get", "PROJ_HEIGHT_2"]], this.vri_min_height],
        ],
        0.5,
        0,
      ];

      return opapacity_expression;
    },

    fetch_gbif() {
      // Note: Not being used, most observations are for eBird and don't include image data

      let base_url = "https://api.gbif.org/v1/occurrence/search";

      let bounds = this.map.getBounds();
      let ne = bounds.getNorthEast();
      let sw = bounds.getSouthWest();

      let url = base_url + "?limit=300";
      url += "&decimalLatitude=" + sw.lat + "," + ne.lat;
      url += "&decimalLongitude=" + sw.lng + "," + ne.lng;
      url += "&datasetKey=4fa7b334-ce0d-4e88-aaae-2e0c138d049e"; // EOD – eBird Observation Dataset
      url += "&datasetKey=8a863029-f435-446a-821e-275f4f641165"; // Observation.org
      url += "&datasetKey=6ac3f774-d9fb-4796-b3e9-92bf6c81c084"; // naturgucker
      url += "&datasetKey=14d5676a-2c54-4f94-9023-1e8dcd822aa0"; // Pl@ntNet automatically identified occurrences

      axios.get(url).then(function (response) {
        console.log(response);
      });
    },
    fetch_inaturalist() {
      let self = this;
      let base_url = "https://api.inaturalist.org/v1/observations";

      let bounds = this.map.getBounds();
      let ne = bounds.getNorthEast();
      let sw = bounds.getSouthWest();

      let url =
        base_url +
        "?acc_below=500&per_page=200&taxon_geoprivacy=open&nelat=" +
        ne.lat +
        "&nelng=" +
        ne.lng +
        "&swlat=" +
        sw.lat +
        "&swlng=" +
        sw.lng +
        "&hrank=genus";
      axios.get(url).then(function (response) {
        let results = response.data.results;
        let features = [];
        for (var item of results) {
          if (item.taxon) {
            if (item.taxon.preferred_common_name) {
              item.display_name = item.taxon.preferred_common_name;
            } else {
              item.display_name = item.taxon.name;
            }
          } else {
            item.display_name = item.species_guess;
          }

          item.sort_order = 1;
          if (item.taxon && item.taxon.threatened) {
            if (item.taxon.conservation_status) {
              item.display_name +=
                " (" + item.taxon.conservation_status.status_name + ")";
            } else {
              item.display_name += " (endangered)";
            }
            item.sort_order = 0;
          }

          features.push({
            type: "Feature",
            geometry: item.geojson,
            properties: item,
          });
        }

        // Add in-view features from existing data as long as it's not duplicate
        for (let existing_feature of self.inat_data) {
          if (bounds.contains(existing_feature.geometry.coordinates)) {
            let found = false;
            for (let feat of features) {
              if (
                feat.properties.id == existing_feature.properties.id &&
                feat.properties.id
              ) {
                found = true;
                break;
              }
            }
            if (!found) {
              features.push(existing_feature);
            }
          }
        }

        self.inat_data = features;

        let geojson = {
          type: "FeatureCollection",
          features: features,
        };

        if (self.map.getSource("inaturalist-geojson")) {
          self.map.getSource("inaturalist-geojson").setData(geojson);

          self.map.moveLayer("inaturalist", "big-trees");
        }
      });
    },
  },
};
</script>

<style>
#map {
  width: 100%;
  height: 100%;
}

#legend {
  width: 300px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.legend-color {
  font-size: 3rem;
  position: relative;
  top: 9px;
}

.small_screen_dialog h3 {
  margin-bottom: 10px;
}

.small_screen_dialog h4 {
  margin-bottom: 5px;
}

.inat_photos img {
  margin-right: 5px;
}

.small_screen_dialog .inat_photos {
  width: 300px;
}

.big_trees_observation {
  width: 500px;
  max-height: 500px;
  overflow-y: scroll;
}

.small_screen_dialog .big_trees_observation {
  width: 100%;
  max-height: 80%;
}

.small_screen_dialog .big_tree_nickname {
  display: none;
}

.mapboxgl-ctrl-group:has(.mapboxgl-ctrl-geolocate) {
  margin-top: 15px;
}
</style>
