<template>
  <v-autocomplete
    v-model="select"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    class="mx-4"
    hide-no-data
    hide-details
    label="Search"
    solo
    dense
    @keydown="press_enter"
  >
    <template v-slot:item="data">
      <v-list-item-avatar>
        <v-icon v-if="!data.item.value.type || data.item.value.type == 'unclassified'" color="blue">
          mdi-map-marker
        </v-icon>
        <v-icon v-if="data.item.value.type == 'water'" color="blue">
          mdi-waves
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'peak'" color="brown">
          mdi-image-filter-hdr
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'volcano'" color="red">
          mdi-volcano
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'big_tree'" color="green">
          mdi-pine-tree
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'coords'" color="black">
          mdi-map
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'city' || data.item.value.type == 'locality' || data.item.value.type == 'town' || data.item.value.type == 'suburb' || data.item.value.type == 'village' || data.item.value.type == 'hamlet'" color="black">
          mdi-city
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'aerodrome'" color="black">
          mdi-airport
        </v-icon>
        <v-icon v-else-if="data.item.value.type.includes('residential') || data.item.value.type.includes('motorway') || data.item.value.type.includes('secondary') || data.item.value.type.includes('tertiary') || data.item.value.type.includes('service') || data.item.value.type.includes('unclass')" color="black">
          mdi-road
        </v-icon>
        <v-icon v-else-if="data.item.value.type.includes('stream') || data.item.value.type.includes('river')" color="blue">
          mdi-waves
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'bay'" color="blue">
          mdi-sail-boat
        </v-icon>
        <v-icon v-else-if="data.item.value.type.includes('path') || data.item.value.type.includes('footway')" color="black">
          mdi-hiking
        </v-icon>
        <v-icon v-else-if="data.item.value.type.includes('track')" color="black">
          mdi-truck-off-road
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'state_district'" color="black">
          mdi-image-outline
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'farm'" color="black">
          mdi-home-silo
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'island' || data.item.value.type == 'islet'" color="black">
          mdi-island-variant
        </v-icon>
        <v-icon v-else-if="data.item.value.type == 'cycleway'" color="black">
          mdi-bike
        </v-icon>
        <p v-else style="font-size: xx-small; overflow: visible">
          {{ data.item.value.type }}
        </p>

      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.text"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "axios";
import Coordinates from "coordinate-parser";
import big_trees_geojson from "../big_trees.json";

export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      key: "WEFgf7dvvnjb8mKT5z1k", //"uzaFSBbfgFUp5zcijcSu", //"iuzaFSBbfgFUp5zgg",
    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    select(val) {
      this.$emit("select", val);
    },
  },
  methods: {
    press_enter(e) {
      if (e.keyCode == 13) {
        try {
          let coords = new Coordinates(this.search);
          let value = { lat: coords.getLatitude(), lon: coords.getLongitude() };
          this.$emit("select", value);
          return;
        } catch (_e) {
          try {
            let url =
              "https://search.osmnames.org/q/" +
              encodeURIComponent(this.search) +
              "%2C%20British%20Columbia.js?key=" +
              this.key;
            axios.get(url).then((response) => {
              let result = response.data.results[0];
              this.$emit("select", result);
            });
          } catch (_e) {
            // DO NOTHING
          }
        }
      }
    },
    querySelections(v) {
      if (!v.trim()) {
        return;
      }

      try {
        let coords = new Coordinates(v);
        let value = { lat: coords.getLatitude(), lon: coords.getLongitude(), type: "coords" };
        this.items = [{ text: value.lat + ", " + value.lon, value: value }];
        return;
      } catch (_e) {
        // DO NOTHING
      }

      let items = [];
      for (const feat of big_trees_geojson.features) {
        if (
          feat.properties.tree_nickname.toLowerCase().includes(v.toLowerCase())
        ) {
          items.push({
            text: feat.properties.tree_nickname,
            value: {
              lat: feat.properties.latitude,
              lon: feat.properties.longitude,
              type: "big_tree",
            },
          });
        }
      }

      this.loading = true;
      try {
        let url =
          "https://search.osmnames.org/q/" +
          encodeURIComponent(v) +
          "%2C%20British%20Columbia.js?key=" +
          this.key;
        axios.get(url).then((response) => {
          for (let result of response.data.results) {
            let text = result.display_name;
            text = text.replace(", British Columbia, Canada", "");
            text = text.replace("Regional District of", "");
            text = text.replace("Regional District", "");
            text = text.replace("Regional Municipality", "");
            text = text.replace("Indian Reserve", "Reserve");
            text = text.replace("Area A", "");
            text = text.replace("Area B", "");
            text = text.replace("Area C", "");
            text = text.replace("Area D", "");
            text = text.replace("Area E", "");
            text = text.replace("Area F", "");
            text = text.trim();

            if (text.startsWith("(")) {
              continue;
            }
            if (result.state != "British Columbia") {
              continue;
            }

            //text += " (" + result.type + ")";
            items.push({ text: text, value: result });
          }
          this.items = items;
          this.loading = false;
        });
      } catch (_e) {
        this.loading = false;
      }
    },
  },
};
</script>
